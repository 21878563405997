.sidebar {
  border-radius: 16px;
  background: linear-gradient(
    180deg,
    #448868 -15.99%,
    #024123 5.82%,
    #0a492c 96.45%,
    #448868 119.17%
  );
  width: 246px;
  padding: 38px 0px;
  margin-block: 21px;
  margin-inline-start: 21px;
  // marginBlock: 21px;
  // margin-right: 43px;
  // margin-right: 21px;
  // height: 85vh;
  height: 95vh;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
  .title {
    color: #fff;
    margin-bottom: 20px;
    margin-left: 13px;
  }
  .menu {
    margin-left: 13px;
    color: #989ba2;
    // font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
  }
  .menu-item {
    display: flex;
    width: auto;
    padding: 10px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 14px;
    text-decoration: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 13px;
    margin-right: 13px;
    margin-bottom: 13px;
    color: #fff;
    text-transform: capitalize;
  }
  .active{
    background: #c6f738;
    color: #022213;
  }

  .divider{
    margin-left: 14px;
  }

}
.page-title{
  color: #044325;
// font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 800;
line-height: normal;
margin-top: 24px;
}

.page{
  display: flex;
}

.verythinscrollnewinv::-webkit-scrollbar {
  width: 0.5em;
  /* margin-block: 12px; */
  // background-color: #f44;
  background-color: transparent;
}

.verythinscrollnewinv::-webkit-scrollbar-track {
  /* margin: 12px; */
  // background-color: #44f;
  background-color: transparent;
}

.verythinscrollnewinv::-webkit-scrollbar-thumb {
  /* background:  #666666; */
  /* background:  #E6E6E6; */
  background: #E6E6E6;
  border-radius: 100vw;
  border: 0.125em solid #E6E6E6;
}

